import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import InputComponent from './Input'
import { createShow, deleteShow, editShow } from '../helpers/shows'
import { getCoordinates } from '../helpers/coordinates'
import firebase from 'firebase'
import FileUploader from 'react-firebase-file-uploader'
import UploadFile from './UploadFile/UploadFile'

const artistFields = [
    {
        text: 'Age',
        placeholder: 'age',
        key: 'age',
        entryType: 'number',
    },
    {
        text: 'Gender',
        key: 'Gender',
        placeholder: 'Gender',
        entryType: 'text',
    },
    {
        text: 'Ethnicity',
        key: 'Ethnicity',
        placeholder: 'Ethnicity',
        entryType: 'text',
    },
    {
        text: 'Location',
        key: 'Location',
        placeholder: 'Location',
        entryType: 'text',
    },
]
const scheduleFields = []
const showFields = [
    {
        text: 'Organization',
        key: 'organization',
        placeholder: 'organization name',
        entryType: 'text',
    },
    {
        text: 'Headline',
        key: 'headline',
        placeholder: 'Short quote',
        entryType: 'text',
    },
]
const descField = [
    {
        text: 'Story',
        placeholder: 'description',
        key: 'description',
        entryType: 'area',
    },
    {
        text: 'Artist Name',
        key: 'artistName',
        placeholder: 'Artist Name',
        entryType: 'text',
    },
    {
        text: 'Artist Link',
        key: 'artistLink',
        placeholder: 'artist link',
        entryType: 'text',
    },
]
const fields = [
    {
        text: 'title',
        key: 'title',
        placeholder: '(optional) Show Title',
        entryType: 'text',
    },
    {
        text: 'start time',
        key: 'startTime',
        placeholder: '',
        entryType: 'showTime',
    },
    {
        text: 'Duration',
        key: 'duration',
        placeholder: '',
        entryType: 'duration',
    },
    {
        text: 'end time',
        key: 'endTime',
        placeholder: '',
        entryType: 'showTime',
        disabled: true,
    },
    {
        text: 'stream link',
        placeholder: 'twitch, vimeo, network.currents...',
        key: 'link',
        entryType: 'text',
    },
]

const optionalFields = []

const customStyles = {
    direction: 'flex',
    flexDirection: 'row',
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '960px',
        height: 640,
        backgroundColor: 'black',
        color: 'white',
        borderRadius: 0,
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,.8)',
    },
}

Modal.setAppElement('#root')

function ShowForm(props) {
    var subtitle
    const { getEdition, handleGetShows, roomData, shows, show, formType, room, edition } = props
    const newShow = {
        type: 'single',
        room,
        link: roomData ? roomData.streamLink : '',
        startTime: new Date(),
        endTime: new Date(),
    }

    const [modalIsOpen, setIsOpen] = useState(false)
    const openModal = () => {
        setIsOpen(true)
    }
    const afterOpenModal = () => {
        subtitle.style.color = '#f00'
    }
    const closeModal = () => setIsOpen(false)

    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0)

    const [showData, setShowData] = useState(newShow)

    const setDataValue = (key, value) => setShowData({ ...showData, [key]: value })

    const id = show && show.id
    useEffect(() => {
        if (show) setShowData({ link: roomData ? roomData.streamLink : '', ...show })
    }, [roomData, show, id])

    useEffect(() => {
        if (modalIsOpen) {
            console.log(showData)
            console.log(show)
        }
    }, [modalIsOpen])

    const { duration, startTime } = showData
    useEffect(() => {
        if (duration !== undefined && startTime) {
            setDataValue('endTime', new Date(showData.startTime.getTime() + duration * 1000))
        }
    }, [duration, startTime])

    const handleGetCoordinates = async (country) => {
        let newCountry = country + ' '
        const data = await getCoordinates(newCountry)
        setShowData({ ...showData, lat: data.latitude, lon: data.longitude })
        if (data !== 'ERROR') {
        } else {
            console.log('error in the get coordinates')
        }
    }
    const handleCreateShow = async (edition, show) => {
        let newShow = { ...show }
        console.log(newShow)
        const data = await createShow(edition, newShow)
        if (data !== 'ERROR') {
            handleGetShows(edition)
            // getEdition(edition)
            console.log('room was added')
            console.log(shows)
            //function to refresh edition data!!!!!!
        } else {
            console.log('error in the add show')
        }
    }

    const handleDeleteShow = async (e) => {
        console.log(show)
        console.log(showData)
        const data = await deleteShow(edition, show.id)
        if (data !== 'ERROR') {
            handleGetShows(edition) //function to refresh edition data!!!!!!
            closeModal()
        } else {
            console.log('error in the remove s how')
        }
    }

    const handleEditShow = async (e) => {
        console.log(show)
        console.log(showData)
        let editedShow = { ...show, ...showData }
        console.log(editedShow)
        const data = await editShow(edition, editedShow, show.id)
        if (data !== 'ERROR') {
            handleGetShows(edition)
            closeModal()
            console.log('show was edited') //function to refresh edition data!!!!!!
        } else {
            console.log('error in the edit show')
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        if (evt.target.value === 'delete') {
            //handleDeleteShow(edition, show.id)
        }
        if (evt.target.value === 'submit edit') {
        }
        if (evt.target.value === 'submit') {
            handleCreateShow(edition, showData)
        }
        setShowData(newShow)
        closeModal()
    }

    //FIXME add placeholders to all the inputs
    return (
        <div>
            <div className="AddShowButton">
                {formType === 'newShow' ? (
                    <button onClick={openModal}>ADD CARD</button>
                ) : (
                    <button onClick={openModal}> Edit</button>
                )}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {formType === 'newShow' ? (
                    <div ref={(_subtitle) => (subtitle = _subtitle)}>ADD CARD</div>
                ) : (
                    <div ref={(_subtitle) => (subtitle = _subtitle)}>EDIT CARD</div>
                )}
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 540, marginTop: 16 }}>
                    <div
                        style={{
                            width: 240,
                            minWidth: 240,
                            paddingRight: 32,
                            borderRight: '1px solid white',
                            marginRight: 32,
                        }}
                    >
                        <div style={{ height: 16 }} />

                        <div className="fieldLabel">IMAGE</div>
                        {isUploading && <p>Progress: {progress}</p>}
                        {showData && showData.image && (
                            <img
                                src={showData.image}
                                style={{
                                    width: 128,
                                    height: 128,
                                    marginBottom: 16,
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                        <UploadFile setFileURL={(url) => setDataValue('image', url)} />
                        <div style={{ height: 16 }} />
                        {artistFields.map((f, i) => {
                            return (
                                <>
                                    <InputComponent
                                        text={f.text}
                                        type={f.entryType}
                                        key={'field' + i}
                                        placeholder={f.placeholder}
                                        formType={formType}
                                        value={showData[f.key]}
                                        disabled={f.disabled}
                                        func={(v) => {
                                            setDataValue(f.key, v && v.target && v.target.value ? v.target.value : v)
                                        }}
                                    />
                                    <div style={{ height: 16 }} />
                                </>
                            )
                        })}
                    </div>
                    <div style={{ width: 'auto' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                            {showFields.map((f, i) => {
                                return (
                                    <>
                                        <InputComponent
                                            text={f.text}
                                            type={f.entryType}
                                            key={'field' + i}
                                            placeholder={f.placeholder}
                                            formType={formType}
                                            value={showData[f.key]}
                                            disabled={f.disabled}
                                            func={(v) => {
                                                setDataValue(
                                                    f.key,
                                                    v && v.target && v.target.value ? v.target.value : v,
                                                )
                                            }}
                                        />
                                        <div style={{ height: 16 }} />
                                    </>
                                )
                            })}
                        </div>
                        {descField.map((f, i) => {
                            return (
                                <>
                                    <InputComponent
                                        text={f.text}
                                        type={f.entryType}
                                        key={'field' + i}
                                        placeholder={f.placeholder}
                                        formType={formType}
                                        value={showData[f.key]}
                                        disabled={f.disabled}
                                        func={(v) => {
                                            setDataValue(f.key, v && v.target && v.target.value ? v.target.value : v)
                                        }}
                                    />
                                    <div style={{ height: 16 }} />
                                </>
                            )
                        })}
                    </div>
                </div>
                <div style={{ marginTop: 16, display: 'flex', flexDirection: 'row' }}>
                    {formType === 'newShow' ? (
                        <input type="submit" value="submit" onClick={handleSubmit} />
                    ) : (
                        <button onClick={handleEditShow}>SUBMIT EDITS</button>
                    )}
                    {formType !== 'newShow' && <button onClick={handleDeleteShow}>DELETE</button>}
                </div>
            </Modal>
        </div>
    )
}

export default ShowForm
