import React, { useState, useEffect } from 'react'
import ShowItem from './ShowItem'
import ShowForm from './ShowForm'
import RoomForm from './RoomForm'
import { getEditionData, createCommonCollective, updateCollective, editRoom } from '../helpers/editionData'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const roomStyle = {
    ///display: 'flex',
    //flexFlow: 'column-wrap',
    columnCount: 3,
    columnGap: '16px',
    
    
    //flex-flow: column wrap;

    maxWidth: '100%',
    width: 'inherit',
    //overflowX: 'hidden',
    //position: 'relative',
    //maxHeight: 'initial',
    //overflowY: 'scroll',

    //maxHeight: ''
}
const roomInfoStyle = {
    outlineWidth: '1px',
    borderBottom: '1px solid rgba(255,255,255,.15)',
    outlineStyle: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 16,
    maxWidth: 320,
    textAlign: 'left',
    position: 'sticky',
    top: 0,
    backgroundColor: 'rgba(0,0,0,.8)',
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

function Show(props) {
    const { showData, index, getEdition, handleGetShows, edition, isEditShow, key, roomKey } = props

    if (showData && showData.id)
        return (
            <Draggable
                draggableId={'show' + index + 'room' + roomKey}
                key={key}
                showData={showData}
                index={index}
                getEdition={getEdition}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={snapshot.isDragging ? { border: '1px solid red' } : { border: '1px solid transparent' }}
                    >
                        <ShowItem showData={showData} getEdition={getEdition} handleGetShows={handleGetShows} />
                    </div>
                )}
            </Draggable>
        )
    return null
}

function Room(props) {
    const { roomData, roomKey, getEdition, handleGetShows, edition, shows, rooms } = props
    // function onDragEnd(result) {
    //     if (!result.destination) {
    //       return;
    //     }
    //     if (result.destination.index === result.source.index) {
    //       return;
    //     }
    //     const shows = reorder(
    //       shows,
    //       result.source.index,
    //       result.destination.index
    //     );
    //     setOrderedShows(shows)
    //   }
    //   useEffect(() => {
    //     if(shows) {
    //       //order shows
    //       getShows(shows)
    //     }
    // }, [shows])
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }
        /*
    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });*/
    }

    const permission = true

    if (!roomData) return null
    if (process.env.REACT_APP_ADMIN !== 'yes') {
        return (
            <div style={{ display: 'flex', width: '100%', padding: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                {/*<div style={{ marginBottom: 8, fontWeight: 500 }}>{roomData.name.toUpperCase()}</div>
                <RoomForm
                    formType="editRoom"
                    roomD={roomData}
                    edition={edition}
                    roomKey={roomKey}
                    getEdition={getEdition}
                />*/}
                <ShowForm
                    edition={edition}
                    handleGetShows={handleGetShows}
                    getEdition={getEdition}
                    formType="newShow"
                    shows={shows}
                    room={roomKey}
                    roomData={roomData}
                />

                {shows && (
                    <div style={roomStyle}>
                        {shows
                            .filter((s) => s.room === roomKey)
                            .sort((a, b) => {
                                return a.startTime.seconds > b.startTime.seconds ? 1 : -1
                            })
                            .map((showData, index) => {
                                showData = {
                                    ...showData,
                                    startObj: showData.startTime,
                                    endObj: showData.endTime,
                                    duration: showData.endTime.seconds - showData.startTime.seconds,
                                    startTime: new Date(showData.startTime.seconds * 1000),
                                    endTime: new Date(showData.endTime.seconds * 1000),
                                }
                                if (permission)
                                    return (
                                        <ShowItem
                                            showData={showData}
                                            getEdition={getEdition}
                                            handleGetShows={handleGetShows}
                                            edition={edition}
                                            permission={permission}
                                        />
                                    )

                                return (
                                    <ShowItem
                                        key={'sh' + roomKey + '_' + index + showData.id}
                                        showData={showData}
                                        getEdition={getEdition}
                                        permission={permission}
                                        handleGetShows={handleGetShows}
                                    />
                                )
                                //return <Show showData={showData} index={index} key={key} roomKey={roomKey} getEdition={getEdition} handleGetShows={handleGetShows} roomData={roomData}/>
                            })}
                    </div>
                )}
            </div>
        )
    }
    return (
        <div className="Room" style={roomStyle}>
            <div className="RoomInfo" style={roomInfoStyle}>
                <img src={roomData.image} style={{ width: '100%', height: 48, objectFit: 'cover' }} />
                <div style={{ opacity: 0.54 }}>Room {roomKey} </div>
                <div style={{ marginBottom: 8, fontWeight: 500 }}>{roomData.name}</div>
                {roomData.subname && (
                    <div style={{ marginTop: -4, marginBottom: 8, fontWeight: 500 }}>{roomData.subname}</div>
                )}
                {permission && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <ShowForm
                            edition={edition}
                            handleGetShows={handleGetShows}
                            getEdition={getEdition}
                            formType="newShow"
                            shows={shows}
                            room={roomKey}
                            roomData={roomData}
                        />
                        <RoomForm
                            formType="editRoom"
                            roomD={roomData}
                            edition={edition}
                            roomKey={roomKey}
                            getEdition={getEdition}
                        />
                        {!roomData.collectiveID && !roomData.commonCollective && (
                            <button
                                onClick={async () => {
                                    // blogID, name, common, blogs
                                    const data = {
                                        name: edition.toUpperCase() + ' ' + roomData.name,
                                        blogs: shows
                                            .filter((s) => s.room === roomKey && s.currentsID)
                                            .map((s) => s.currentsID),
                                    }
                                    let response = await createCommonCollective(data)
                                    console.log(response)
                                    if (response && response.id) {
                                        await editRoom(edition, null, {
                                            ...roomData,
                                            key: props.rooms[roomKey],
                                            commonCollective: response.id,
                                        })
                                    }
                                }}
                            >
                                CREATE COLLECTIVE
                            </button>
                        )}
                        {roomData.commonCollective && (
                            <button
                                onClick={async () => {
                                    // blogID, name, common, blogs
                                    const data = {
                                        collectiveID: roomData.commonCollective,
                                        blogs: shows
                                            .filter((s) => s.room === roomKey && s.currentsID)
                                            .map((s) => s.currentsID),
                                    }
                                    let response = await updateCollective(data)
                                    console.log(response)
                                }}
                            >
                                UPDATE COLLECTIVE
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div className="RoomLineup">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={'roomlist' + roomData.roomId}>
                        {(provided, snapshot) => {
                            if (shows)
                                return (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {shows
                                            .filter((s) => s.room === roomKey)
                                            .sort((a, b) => {
                                                return a.startTime.seconds > b.startTime.seconds ? 1 : -1
                                            })
                                            .map((showData, index) => {
                                                showData = {
                                                    ...showData,
                                                    startObj: showData.startTime,
                                                    endObj: showData.endTime,
                                                    duration: showData.endTime.seconds - showData.startTime.seconds,
                                                    startTime: new Date(showData.startTime.seconds * 1000),
                                                    endTime: new Date(showData.endTime.seconds * 1000),
                                                }
                                                if (permission)
                                                    return (
                                                        <Draggable
                                                            key={'sh' + roomKey + '_' + index + showData.id}
                                                            draggableId={showData.id}
                                                            showData={showData}
                                                            index={index}
                                                            getEdition={getEdition}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={
                                                                        snapshot.isDragging
                                                                            ? {
                                                                                  border: '1px solid red',
                                                                                  ...provided.draggableProps.style,
                                                                              }
                                                                            : {
                                                                                  border: '1px solid transparent',
                                                                                  ...provided.draggableProps.style,
                                                                              }
                                                                    }
                                                                >
                                                                    <ShowItem
                                                                        showData={showData}
                                                                        getEdition={getEdition}
                                                                        handleGetShows={handleGetShows}
                                                                        edition={edition}
                                                                        permission={permission}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                return (
                                                    <ShowItem
                                                        key={'sh' + roomKey + '_' + index + showData.id}
                                                        showData={showData}
                                                        getEdition={getEdition}
                                                        permission={permission}
                                                        handleGetShows={handleGetShows}
                                                    />
                                                )
                                                //return <Show showData={showData} index={index} key={key} roomKey={roomKey} getEdition={getEdition} handleGetShows={handleGetShows} roomData={roomData}/>
                                            })}
                                        {provided.placeholder}
                                    </div>
                                )
                        }}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
}

export default Room
