import firebase from 'firebase'
import axios from 'axios'
//export const
const fileContents = async (file) => {
    // get the contents hash of a file from an <input type="file"> field
    return await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            resolve(event.target.result)
        }
        reader.readAsArrayBuffer(file)
    })
}

//export const
const fileHash = async (arrayBuffer) => {
    // get the SHA1 hash of a file's contents from an <input type="file"> field
    const hashBuffer = await crypto.subtle.digest('SHA-1', arrayBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
    return hashHex
}

const getUploadURL = async (filename, image = false) => {
    let token = (await firebase.auth().currentUser) && firebase.auth().currentUser.getIdToken()
    const body = JSON.stringify({
        uid: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    })
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/getUploadURL${image ? 'img' : ''}`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body,
    })
        .then(async (response) => {
            return response.json()
        })
        .then((result) => {
            //console.log(result)
            return result
        })
}

export const uploadImageB2 = (uploadUrl, file, filename, uploadToken, hash, uid, blogID, onProgressCallback) => {
    return axios
        .post(uploadUrl, file, {
            headers: {
                Authorization: uploadToken,
                'Content-Type': 'image/jpeg', // helps prevent reflecting user-uploads as JS/HTML/viruses/etc
                'X-Bz-File-Name': filename,
                'X-Bz-Content-Sha1': hash,
                'X-Bz-Info-Filename': encodeURIComponent(file.name),
                'X-Bz-Info-Uid': uid,
            },
            onUploadProgress: ({ loaded, total }) => {
                const totalProgress = parseInt((loaded / total) * 100)
                onProgressCallback(totalProgress)
                //     console.log('Uploading...', `${totalProgress}%`)
            },
        })
        .then((response) => {
            console.log(response)
            return response.data
        })
}

export const uploadImage = async (file, user, onProgressCallback) => {
    const uid = user && user.uid
    try {
        const contentArrayBuffer = await fileContents(file)
        const hash = await fileHash(contentArrayBuffer) // console.log("calculating file hash...")
        const filename = `${hash}.jpg`
        const publicUrl = `${process.env.REACT_APP_B2_BASE_URL}${filename}`
        const { uploadUrl, uploadToken } = await getUploadURL(filename, true) //console.log("Getting upload url...", filename, hash)
        const { fileId } = await uploadImageB2(
            uploadUrl,
            file,
            filename,
            uploadToken,
            hash,
            uid,
            'new',
            onProgressCallback,
        )

        return publicUrl
    } catch (e) {
        console.log(e)
        return false
    }
}
