import React, { useState } from 'react'
import { moveShow } from '../helpers/shows'
import ShowForm from './ShowForm'

const showStyle = {
    //margin: 24,
    //marginLeft: 0,
    padding: 16,
    border: '1px solid rgba(255,255,255,.33)',
    textAlign: 'left',
    //display: 'inline-block',
    breakInside: 'avoid-column',
    //display: 'inline-block',
    margin: '0 0 16px',
    width: 'inherit',
    //height: 'fit-content'
    //position: 'absolute',
    width: 'calc(33vw - 72px)',
    maxWidth: 'calc(33vw - 32px)',
}

const showLeftStyle = {
    fontSize: 12,
    lineHeight: '18px',
    textAlign: 'left',
}

const showMiddleStyle = {
    textAlign: 'left',
}
const showRightStyle = {
    textAlign: 'right',
    alignItems: 'left',
}

const imageDivStyle = {
    maxHeight: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
}
const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
}

function ShowItem(props) {
    var subtitle
    const { showData, handleGetShows, getEdition, permission, edition } = props

    return (
        <div className="Show" style={showStyle}>
            <div
                className="top"
                style={{
                    width: '100%',
                    opacity: 1,
                    height: 128,
                    position: 'relative',
                    cursor: 'pointer',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url("${showData.image}") `,
                    zIndex: 0,
                }}
            >
                <div
                    className="graffiti"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: 128,
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>{showData.artistName}</div>
                    <a href={showData.artistLink} className="link">link to website</a>
                </div>
            </div>
            <div className="ShowLeft" style={showLeftStyle}>
                <b>《 {showData.organization} 》</b>
            </div>
            <div style={{ marginBottom: 15 }}></div>
            {showData && showData.headline && (
                <div className="stylizedTitle" style={{ fontSize: 28, lineHeight: '36px' }}>
                    “{showData.headline}”
                </div>
            )}
            <div style={{ marginBottom: 15 }}>
                <b>{showData.company}</b>
            </div>

            <div className="ShowLeft" style={showLeftStyle}>
                {/*
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: 48 }}>DATE</div>
                            <div style={{ fontStyle: 'italic' }}>
                                {showData.startObj
                                    ? new Date(showData.startObj.seconds * 1000).toString()
                                    : showData.startTime.toString()}
                            </div>
                                </div>*/}

                <div>
                    <b>GENDER ‣ {showData.gender}</b>
                </div>
                <div>
                    <b>ETHNICITY ‣ {showData.ethnicity}</b>
                </div>
                <div style={{ marginBottom: 15 }}></div>
                <div style={{}} className="txtb">
                    “{showData.description}”
                </div>

                {permission && (
                    <ShowForm
                        show={showData}
                        edition={edition}
                        formType="editShow"
                        handleGetShows={handleGetShows}
                        getEdition={getEdition}
                    />
                )}
            </div>

            <div className="ShowRight" style={showRightStyle}></div>
        </div>
    )
}

export default ShowItem
