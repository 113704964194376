import React, { useState } from 'react'
import { useEffect } from 'react'
import Modal from 'react-modal'

const quizStates = {
    0: {
        q: "Are you a white male?",
        color: "colorYellow colorYellowBorder",
        c: [
            {
                t: 'YES',
                i: 'RESOURCE'
            },
            {
                t: 'NO',
                i: 1
            }
        ]
    },
    1: {
        q: "Is 50% or more of your income a result of a musical project you're involved with?",
        color: "colorBlue colorBlueBorder",
        c: [
            {
                t: 'YES',
                i: 2
            },
            {
                t: 'NO',
                i: 'RESOURCE'
            }
        ]
    },
    2: {
        q: "Have you ever performed any of the following services for a gear manufacturer?",
        color: "colorRed colorRedBorder",
        c: [
            {
                t: 'Created content by posing with gear, demo videos, blog posts, and other social media',
                i: 3
            },
            {
                t: 'Trade show, store demo, or other personal appearances',
                i: 3
            },
            {
                t: 'Answering questions for a specific manufacturer or product',
                i: 3
            },
            {
                t: 'Created patches, sound design, or elements onboard a product',
                i: 3
            },
            {
                t: 'Given feedback, improvement ideas, or any other market research. ',
                i: 3
            },
            {
                t: 'None of the above',
                i: 'RESOURCE'
            }
        ]
    },
    3: {
        q: "How many hours (including prep time) did you devote to this project?",
        color: "colorYellow colorYellowBorder",
        c: [
            {
                t: 'More than an hour',
                i: 4
            },
            {
                t: 'Less than an hour',
                i: 'RESOURCE'
            }
        ]
    },
    4: {
        q: "Were you compensated with money for any of those hours?",
        color: "colorBlue colorBlueBorder",
        c: [
            {
                t: 'YES',
                i: 'RESOURCE'
            },
            {
                t: 'NO',
                i: 5
            }
        ]
    },
    5: {
        q: "Were you provided with the following: ",
        color: "colorYellow colorYellowBorder",
        c: [
            {
                t: 'Discount',
                i: 6
            },
            {
                t: 'Nothing',
                i: 6
            },
            {
                t: 'Gear',
                i: 6
            },
            {
                t: 'Dinner',
                i: 6
            },
            {
                t: 'Hotel stay, transportation, expenses',
                i: 6
            }
        ]
    },
    6: {
        q: "You have been exploited :( ",
        color: "colorRed colorRedBorder",
        c: [
            {
                t: 'VIEW MANIFESTO',
                i: 'MANIFESTO'
            }
        ]
    },
    RESOURCE: {
        q: 'RESOURCE LINKS',
        c: []
    },
    MANIFESTO: {
        q: 'MANIFESTO',
        c: []
    }

}


function Quiz(props) {
    const { open, setOpen } = props
    const [stage, setStage] = useState(0)

    useEffect(() => {
        setStage(0)
    }, [open])

    const form = quizStates[stage]

    return (
        <>
            <Modal
                isOpen={open}
                onRequestClose={() => setOpen(false)}
                style={{
                    overlay: { backgroundColor: 'rgba(0,0,0,.7)' },
                    content: { backgroundColor: 'black', border: '0px', color: 'white' },
                }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div> </div>
                    <div style={{ fontSize: 24 }} onClick={() => setOpen(false)}>
                        ✕
                    </div>
                </div>
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{fontSize: 24, marginBottom: 48}}>{form.q}</div>
                <div style={{width: 600}}>{form.c.map((ch, chi) => {
                    return <div key={chi}>

<button
                                            className={form.color}
                                            style={{ width: '100%', marginBottom: 24 }}
                                            onClick={() => setStage(ch.i)}
                                        >
                                            {ch.t}
                                            </button>
                    </div>
                })}</div>
                </div>
                
            </Modal>
        </>
    )
}

export default Quiz
