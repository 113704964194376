import React, { useState, useEffect } from 'react'
import Room from './Room'
import RoomForm from './RoomForm'
import { createCommonCollective, getEditionData, setEditionCollective, updateCollective } from '../helpers/editionData'
import { getShows } from '../helpers/shows'
import '../styles/schnyder-s-normal-600.ttf'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'
import Quiz from './Quiz'


//const edition = 'test'

const roomContainerStyle = {
    margin: 48,
    width: 'calc(100% - 96px)',
}

const dashboardButtonStyle = {
    padding: 40,
    marginTop: '20vh',
}

const dashboardTitleStyle = {
    fontSize: 96,
    fontWeight: 300,
    marginBottom: 20,
    padding: 0,
    paddingLeft: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
}

function Dashboard() {
    const [edition, setEdition] = useState('suzi')
    const [editionText, setEditionText] = useState('')
    const [editionData, setEditionData] = useState(false)
    const [shows, setShows] = useState(false)

    const [open, setOpen] = useState(false)
    const [qopen, setQOpen] = useState(false)

    const [room, setSelectedRoom] = useState(false)

    const handleGetShows = async (edition) => {
        const data = await getShows(edition)
        if (data !== 'ERROR') {
            setShows(data)
        } else {
            console.log('error in the get edition')
        }
    }
    const getEdition = async (edition) => {
        const data = await getEditionData(edition)
        if (data !== 'ERROR') {
            console.log(data)
            setEditionData(data)
        } else {
            console.log('error in the get edition')
        }
    }

    useEffect(() => {
        if (edition) {
            getEdition(edition)
            handleGetShows(edition)
        }
    }, [edition])

    //console.log(shows)

    return (
        <div
            className="Dashboard"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
        >
            <div
                style={{
                    width: '100%',
                    //minWidth: '25vw',
                    //maxHeight: '100vh',
                    overflowY: 'hidden',
                    textAlign: 'left',
                    borderRight: '1px solid white',
                }}
            >
                <div className="DashBoardButton" style={dashboardButtonStyle}>
                    <div className="dashboardTitle stylizedTitle" style={dashboardTitleStyle}>
                        <span className="colorBlue">Cant</span>
                        <span className="colorRed">Eat</span>
                        <span className="colorYellow">Synths</span>
                    </div>

                    <div style={{ height: 24 }} />
                    {edition && (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className="txtb" style={{ lineHeight: '32px', width: '50%' }}>
                                For many years, I have been moved by the blue at the far edge of what can be seen, that colour of horizons, of remote mountain ranges, of anything far away. The colour of that distance is the colour of an emotion, the colour of solitude and of desire, the colour of there seen from here, the colour of where you are not. And the colour of where you can never go. For the blue is not in the place those miles away at the horizon, but in the atmospheric distance between you and the mountains.
                                </div>

                                <div style={{ height: 64 }} />
                                <div style={{ marginLeft: 0 }}>
                                    <div>
                                        <button
                                            className="colorYellow colorYellowBorder"
                                            style={{ width: '100%' }}
                                            onClick={() => setQOpen(true)}
                                        >
                                            TAKE THE QUIZ
                                        </button>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                        <div>
                                            <button className="colorRed colorRedBorder" onClick={() => setOpen(true)}>
                                                ABOUT THE PROJECT
                                            </button>
                                        </div>
                                        <div style={{ width: 15 }} />

                                        <div>
                                            <button className="colorBlue colorBlueBorder" onClick={() => setOpen(true)}>
                                                SUBMIT YOUR ART
                                            </button>
                                        </div>
                                        <div style={{ width: 15 }} />
                                        <div>
                                            <button className="colorBlue colorBlueBorder" onClick={() => setOpen(true)}>
                                                I NEED HELP
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*editionData && editionData.rooms ? (
                                <RoomForm
                                    edition={edition}
                                    getEdition={getEdition}
                                    roomsArr={editionData.rooms}
                                    formType="newRoom"
                                />
                            ) : (
                                <RoomForm edition={edition} getEdition={getEdition} formType="newRoom" />
                            )*/}
                        </>
                    )}
                </div>
            </div>
            <div style={{ overflowY: 'scroll', flex: 1, maxWidth: '100vw' }}>
                {edition && editionData && (
                    <>
                        <div className="RoomContainer" style={roomContainerStyle}>
                            {editionData && editionData.organizers && shows
                                ? editionData.rooms.map((room, index) => {
                                      return (
                                          <Room
                                              edition={edition}
                                              roomData={editionData.organizers[room]}
                                              rooms={editionData.rooms}
                                              roomKey={index}
                                              getEdition={getEdition}
                                              handleGetShows={handleGetShows}
                                              shows={shows}
                                              key={index}
                                          />
                                      )
                                  })
                                : null}
                        </div>
                    </>
                )}
            </div>
            <Quiz open={qopen} setOpen={setQOpen} />
            <Modal
                isOpen={open}
                onRequestClose={() => setOpen(false)}
                style={{
                    overlay: { backgroundColor: 'rgba(0,0,0,.7)' },
                    content: { backgroundColor: 'black', border: '0px', color: 'white' },
                }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div> </div>
                    <div style={{ fontSize: 24 }} onClick={() => setOpen(false)}>
                        ✕
                    </div>
                </div>
                <ReactPlayer
                    url={'https://www.youtube.com/watch?v=QmUbdJLoCtA'}
                    width={'100%'}
                    height={'90%'}
                    config={{
                        twitch: {
                            controls: false,
                            branding: false,
                            showInfo: false,
                        },
                        youtube: {
                            playerVars: {
                                modestbranding: 1,
                                showinfo: 0,
                                controls: 0,
                                rel: 0,
                            },
                            preload: true,
                        },
                    }}
                />
            </Modal>
        </div>
    )
}

export default Dashboard
