import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import InputComponent from './Input'
import RoomInputComponent from './InputRooms'
import { createRoom, editRoom, deleteRoom } from '../helpers/editionData'

//const edition = 'test';//FIXME this should be passed in

const roomFormStyle = {
    paddingTop: '5vh',
    paddingBottom: '10vh',
}

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,.8)',
    },
    content: {
        color: 'white',
        backgroundColor: 'black',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
}

Modal.setAppElement('#root')

function RoomForm(props) {
    var subtitle
    const { getEdition, edition, formType, roomD, roomKey, roomsArr, index } = props

    //opening modal
    const [modalIsOpen, setIsOpen] = React.useState(false)
    const openModal = () => {
        setIsOpen(true)
    }
    const afterOpenModal = () => {
        subtitle.style.color = '#f00'
    }
    const closeModal = () => {
        setIsOpen(false)
    }

    const [roomId, setRoomId] = useState(roomKey) //room id

    const [room, setRoom] = useState({ ...roomD })
    const [key, setKey] = useState(false)
    const [roomData, setRoomData] = useState({})

    //const [roomData, setRoomData] = useState(newShow);
    useEffect(() => {
        if (roomD) setRoomData({ ...roomD })
    }, [roomD, roomKey, edition, index])

    const setDataValue = (key, value) => setRoomData({ ...roomData, [key]: value })

    const handleChange = (event) => {
        if (event.target.name === 'roomStartTime') {
            console.log(event.target.value)
            console.log(new Date(event.target.value))
            setRoom({ ...room, [event.target.name]: new Date(event.currentTarget.value) })
        } else {
            setRoom({ ...room, [event.target.name]: event.currentTarget.value })
        }
    }

    const handleCreateRoom = async (edition, key, room) => {
        let newRoom = { ...roomData }
        const data = await createRoom(edition, key, newRoom)
        if (data !== 'ERROR') {
            getEdition(edition)
            console.log('room was added')
            //function to refresh edition data!!!!!!
        } else {
            console.log('error in the add edition')
        }
    }
    const handleEditRoom = async (edition, key, room) => {
        let newRoom = { ...roomData }
        const data = await editRoom(edition, key, newRoom)
        if (data !== 'ERROR') {
            getEdition(edition)
            console.log('room was edited')
        } else {
            console.log('error in the edit edition')
        }
    }
    const handleDeleteRoom = async () => {
        const data = await deleteRoom(edition, roomKey)
        if (data !== 'ERROR') {
            getEdition(edition)
            console.log('room was deleted')
        } else {
            console.log('error in the edit edition')
        }
    }
    //FIXME consolidate the state and setter
    const handleSubmit = (evt) => {
        console.log(room)
        if (evt.target.value === 'delete') {
            handleDeleteRoom(edition, roomKey)
        } else if (evt.target.value === 'submit edit') {
            handleEditRoom(edition, roomKey, room)
        } else {
            handleCreateRoom(edition, key, room)
        }
        setRoom(false)
        setRoomId(false)
        setKey('')
        closeModal()
        evt.preventDefault()
    }

    const newFields = [
        {
            text: 'name',
            key: 'name',
            placeholder: 'Room Name',
            entryType: 'text',
        },
        {
            text: 'subname (optional)',
            key: 'subname',
            placeholder: 'Room Presented By',
            entryType: 'text',
        },
        {
            text: 'Abbreviation/Code',
            key: 'key',
            placeholder: '2-3 letters',
            entryType: 'text',
        },
        {
            text: 'location',
            key: 'location',
            placeholder: 'location',
            entryType: 'text',
        },
        {
            text: 'Collective ID',
            key: 'collectiveID',
            placeholder: '',
            entryType: 'text',
        },
        {
            text: 'Admin Channel ID',
            key: 'adminID',
            placeholder: '',
            entryType: 'text',
        },
        {
            text: 'stream link',
            key: 'link',
            placeholder: '',
            entryType: 'text',
        },
        {
            text: 'description',
            key: 'description',
            placeholder: '',
            entryType: 'area',
        },
        {
            text: 'cover image',
            key: 'cover image',
            placeholder: '',
            entryType: 'text',
        },
    ]
    const fields = [
        {
            text: 'name',
            key: 'name',
            placeholder: 'Room Name',
            entryType: 'text',
        },
        {
            text: 'subname (optional)',
            key: 'subname',
            placeholder: 'Room Presented By',
            entryType: 'text',
        },
        {
            text: 'location',
            key: 'location',
            placeholder: 'location',
            entryType: 'text',
        },
        {
            text: 'Collective ID',
            key: 'commonCollective',
            placeholder: '',
            entryType: 'text',
        },
        {
            text: 'Admin Channel ID',
            key: 'adminID',
            placeholder: '',
            entryType: 'text',
        },
        {
            text: 'stream link',
            key: 'link',
            placeholder: '',
            entryType: 'text',
        },
        {
            text: 'cover image',
            key: 'image',
            placeholder: '',
            entryType: 'text',
        },
        {
            text: 'description',
            key: 'description',
            placeholder: '',
            entryType: 'area',
        },
    ]
    return (
        <div className="RoomForm">
            {formType === 'newRoom' ? (
                <button onClick={openModal}>Add Room</button>
            ) : (
                <button onClick={openModal}>Edit Room</button>
            )}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {formType === 'newRoom' ? (
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Add Room</h2>
                ) : (
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Edit Room</h2>
                )}
                <form onSubmit={handleSubmit}>
                    {formType === 'newRoom'
                        ? newFields.map((f, i) => {
                              return (
                                  <InputComponent
                                      text={f.text}
                                      type={f.entryType}
                                      key={'field' + i}
                                      placeholder={f.placeholder}
                                      formType={formType}
                                      value={roomData[f.key]}
                                      roomData={roomData}
                                      disabled={f.disabled}
                                      func={(v) => {
                                          setDataValue(f.key, v && v.target && v.target.value ? v.target.value : v)
                                      }}
                                  />
                              )
                          })
                        : fields.map((f, i) => {
                              return (
                                  <InputComponent
                                      text={f.text}
                                      type={f.entryType}
                                      key={'field' + i}
                                      placeholder={f.placeholder}
                                      formType={formType}
                                      value={roomData[f.key]}
                                      roomData={roomData}
                                      disabled={f.disabled}
                                      func={(v) => {
                                          setDataValue(f.key, v && v.target && v.target.value ? v.target.value : v)
                                      }}
                                  />
                              )
                          })}
                    {formType === 'newRoom' ? (
                        <input type="submit" value="Submit" />
                    ) : (
                        <input type="submit" value="submit edit" onClick={handleSubmit} />
                    )}
                    {formType !== 'newRoom' && false ? (
                        <input type="submit" value="delete" onClick={handleSubmit} />
                    ) : (
                        <></>
                    )}
                </form>
            </Modal>
        </div>
    )
}

export default RoomForm
